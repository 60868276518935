(function () {
  const signupPopup = document.querySelector("#signup-popup");
  const quotePopup = document.querySelector("#quote-popup");

  const closeButtons = document.querySelectorAll(".popup-close");
  const body = document.querySelector("body");

  function closePopups() {
    signupPopup?.classList.remove("show");
    quotePopup?.classList.remove("show");
    body.classList.remove("no-scroll");
  }

  closeButtons?.forEach((button) => {
    button.addEventListener("click", () => {
      closePopups();
    });
  });

  signupPopup?.addEventListener("click", (e) => {
    if (e.target === signupPopup) {
      closePopups();
    }
  });

  quotePopup?.addEventListener("click", (e) => {
    if (e.target === quotePopup) {
      closePopups();
    }
  });

  const signupButtons = document.querySelectorAll(".signup-button");
  const quoteButtons = document.querySelectorAll(".quote-button");

  signupButtons?.forEach((button) => {
    button.addEventListener("click", () => {
      const offering = button.getAttribute("data-offering");
      signupPopup?.classList.add("show");
      body.classList.add("no-scroll");
      const input = signupPopup?.querySelector("input.offering-input");
      if (input) {
        input.value = offering;
      }
    });
  });

  quoteButtons?.forEach((button) => {
    button.addEventListener("click", () => {
      const offering = button.getAttribute("data-offering");
      quotePopup?.classList.add("show");
      body.classList.add("no-scroll");
      const input = quotePopup?.querySelector("input.offering-input");
      if (input) {
        input.value = offering;
      }
    });
  });
})();
